import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    meta: {
      title: '优越车网信息科技'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/financial',
    name: 'financial',
    meta: {
      title: '优越金融'
    },
    component: () => import('../views/Financial.vue')
  }, {
    path: '/integral',
    name: 'integral',
    meta: {
      title: '优越积分'
    },
    component: () => import('../views/Integral.vue')
  }, {
    path: '/marketing',
    name: 'marketing',
    meta: {
      title: '优越营销'
    },
    component: () => import('../views/Marketing.vue')
  }, {
    path: '/welfare',
    name: 'welfare',
    meta: {
      title: '优越福利'
    },
    component: () => import('../views/Welfare.vue')
  }, {
    path: '/news',
    name: 'news',
    meta: {
      title: '新闻动态'
    },
    component: () => import('../views/News.vue')
  }, {
    path: '/newsDetail',
    name: 'newsDetail',
    meta: {
      title: '新闻动态详情'
    },
    component: () => import('../views/NewsDetail.vue')
  }
]

const router = new VueRouter({
  mode: 'hash', //去#
  routes
})

export default router
