import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button
} from 'vant';
import {
  Collapse,
  CollapseItem
} from 'vant';
import {
  Pagination
} from 'vant';
import {
  Icon
} from 'vant';
import {
  Toast
} from 'vant';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);

// Vue.config.productionTip = false;


// 根据路由设置标题
router.beforeEach((to, from, next) => {
  /*路由发生改变修改页面的title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

axios.interceptors.request.use(config => {

  // console.log('config.data ==> ', config.data);

  if (config.method === 'post') {

    //序列化post请求参数
    let paramsString = '';
    for (let key in config.data) {
      paramsString += `${key}=${config.data[key]}&`;
    }

    config.data = paramsString.slice(0, -1);

    // console.log('config.data ==> ', config.data);

  }

  //必须返回config
  return config;

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
